import React, { useEffect, useState } from "react";
import { HashLink } from "react-router-hash-link";
// Assets
import Logo from "../../assets/logo.png";
import Resume from "../../assets/CarlosGuzmanFrontEndResumeUpdated.pdf";
import Avatar from "../../assets/selfPortrait2021.jpg";
import SecndChance from "../../assets/websiteScreenshots/secndchance.us_ (2).png";
import Three14 from "../../assets/websiteScreenshots/three14.llc_ (1).png";
import Alpha from "../../assets/websiteScreenshots/localhost_3000_f_realmadrid2.png";
import Ecommerce from "../../assets/websiteScreenshots/ecommerceWebsite.png";
import OldPortfolio from "../../assets/websiteScreenshots/localhost_3000_resume.png";
import OutFurDelivery from "../../assets/websiteScreenshots/cvrlxsguzmanhosting.com_outfurdelivery_ (1).png";
// Icons
import {
  FiGithub,
  FiInstagram,
  FiTwitter,
  FiChevronsRight,
  FiExternalLink,
  FiMenu,
  FiLinkedin,
} from "react-icons/fi";
import {
  IoLocationOutline,
  IoMailOutline,
  IoPhonePortraitOutline,
  IoRocketOutline,
} from "react-icons/io5";
// Styles
import "./styles.scss";
// Firebase
import { analytics, logEvent } from "../../firebase/config";

const HomePage = () => {
  const [showNav, setShowNav] = useState(false);

  // Function to track section views
  const trackSectionView = (sectionId) => {
    logEvent(analytics, "viewed_section", { section: sectionId });
  };

  // Function to track clicks
  const trackClick = (eventName, details = {}) => {
    logEvent(analytics, eventName, details);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        showNav &&
        !event.target.closest(".mobileNav") &&
        !event.target.closest(".bars")
      ) {
        setShowNav(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, [showNav]);

  useEffect(() => {
    // Track when a user visits the site
    logEvent(analytics, "visited_site");

    // Track section views
    const sections = document.querySelectorAll("section, div[id]");
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            logEvent(analytics, "viewed_section", { section: entry.target.id });
          }
        });
      },
      { threshold: 0.5 } // Fires event when at least 50% of the section is visible
    );

    sections.forEach((section) => observer.observe(section));

    // Track scroll depth
    const handleScroll = () => {
      let scrollDepth = Math.round(
        (window.scrollY / document.body.scrollHeight) * 100
      );
      if (scrollDepth % 25 === 0) {
        logEvent(analytics, "scroll_depth", { percentage: scrollDepth });
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      sections.forEach((section) => observer.unobserve(section));
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div className="home-page">
      {/* Navbar */}
      <div className="navbar fontCode">
        <HashLink to={`/#home`} className="logo">
          <img src={Logo} alt="logo" />
        </HashLink>
        <div className="links">
          <HashLink
            to={`/#about`}
            onClick={() =>
              logEvent(analytics, "clicked_nav", { section: "about" })
            }
          >
            About
          </HashLink>
          <HashLink
            to={`/#experience`}
            onClick={() =>
              logEvent(analytics, "clicked_nav", { section: "experience" })
            }
          >
            Experience
          </HashLink>
          <HashLink
            to={`/#latest1`}
            onClick={() =>
              logEvent(analytics, "clicked_nav", { section: "latest_projects" })
            }
          >
            Latest Projects
          </HashLink>
          <HashLink
            to={`/#contact`}
            onClick={() =>
              logEvent(analytics, "clicked_nav", { section: "contact" })
            }
          >
            Contact
          </HashLink>
          <a
            href={Resume}
            download="CarlosGuzmanResume"
            className="resumeButton"
            onClick={() => logEvent(analytics, "clicked_resume")}
          >
            Resume
          </a>
        </div>
      </div>

      {/* Mobile Navbar */}
      <div className="collapseNavbar fontCode">
        <HashLink to={`/#home`} className="logo">
          <img src={Logo} alt="logo" />
        </HashLink>
        <button className="bars" onClick={() => setShowNav(!showNav)}>
          <FiMenu />
        </button>
      </div>

      <div className={showNav ? "active mobileNav" : "mobileNav"}>
        <div className="links">
          <HashLink
            to={`/#about`}
            onClick={() =>
              logEvent(analytics, "clicked_nav", { section: "about" })
            }
          >
            About
          </HashLink>
          <HashLink
            to={`/#experience`}
            onClick={() =>
              logEvent(analytics, "clicked_nav", { section: "experience" })
            }
          >
            Experience
          </HashLink>
          <HashLink
            to={`/#latest1`}
            onClick={() =>
              logEvent(analytics, "clicked_nav", { section: "latest_projects" })
            }
          >
            Latest Projects
          </HashLink>
          <HashLink
            to={`/#contact`}
            onClick={() =>
              logEvent(analytics, "clicked_nav", { section: "contact" })
            }
          >
            Contact
          </HashLink>
          <a
            href={Resume}
            download="CarlosGuzmanResume"
            className="resumeButton"
            onClick={() => logEvent(analytics, "clicked_resume")}
          >
            Resume
          </a>
        </div>
      </div>

      {/* Social Media Links */}
      <div className="socials">
        <a
          href="https://github.com/cvrlxsguzman"
          target="_blank"
          rel="noopener noreferrer"
          onClick={() =>
            logEvent(analytics, "outbound_click", { destination: "GitHub" })
          }
        >
          <FiGithub />
        </a>
        <a
          href="https://twitter.com/cvrlxsguzman"
          target="_blank"
          rel="noopener noreferrer"
          onClick={() =>
            logEvent(analytics, "outbound_click", { destination: "Twitter" })
          }
        >
          <FiTwitter />
        </a>
        <a
          href="https://www.instagram.com/iamcarlosguzman/"
          target="_blank"
          rel="noopener noreferrer"
          onClick={() =>
            logEvent(analytics, "outbound_click", { destination: "Instagram" })
          }
        >
          <FiInstagram />
        </a>
        <a
          href="https://www.linkedin.com/in/carlos-guzman-292863202"
          target="_blank"
          rel="noopener noreferrer"
          onClick={() =>
            logEvent(analytics, "outbound_click", { destination: "LinkedIn" })
          }
        >
          <FiLinkedin />
        </a>
      </div>

      {/* Hero Section */}
      <div className="full container fontRoboto" id="home">
        <p className="lightBlue">Hello, I am</p>
        <h1>Carlos Guzman.</h1>
        <h1 className="gray">I build software & analyze data.</h1>
        <p className="gray">
          I’m a **software developer, data science student, and predictive
          maintenance technician**. I specialize in **web development,
          automation, and data analysis** to create reliable and efficient
          solutions. I’m currently expanding my skills in **AI and machine
          learning** to better understand how data can drive smarter
          decision-making.
        </p>
      </div>

      {/* Sections for Scroll Tracking */}
      <div className="container">
        <div className="full fontNoto" id="about">
          <h1>About Me</h1>
          <div className="content">
            {/*<p className="fontRoboto">*/}
            {/*  Hello, my name is Carlos Guzman. I am a developer that enjoys*/}
            {/*  creating software. I was first introduced to HTML and CSS when I*/}
            {/*  was 11 in 2007-08 with the use of MySpace. Back then, MySpace used*/}
            {/*  to allow you to apply custom themes that you could copy from the*/}
            {/*  internet. I tinkered with them a lot, and gained a passion for*/}
            {/*  creating.*/}
            {/*  <br />*/}
            {/*  <br />I graduated from Lakeshore Technical College in 2021 with an*/}
            {/*  Associates in Science: IT-Web & Software developing degree. I also*/}
            {/*  obtained a certificate in Web Development. While in school, I had*/}
            {/*  the opportunity to tutor software development. I was able to use*/}
            {/*  my skills to teach others and learn more about my own*/}
            {/*  capabilities.*/}
            {/*  <br />*/}
            {/*  <br />*/}
            {/*  Recently, my focus has been on expanding my knowledge in front-end*/}
            {/*  design and development predominantly with ReactJS and React*/}
            {/*  Native.*/}
            {/*</p>*/}
            <p className="fontRoboto">
              Hey, I'm Carlos Guzman, a **software developer, data science
              student, and predictive maintenance technician**. My passion for
              coding started back in 2007 when I first experimented with **HTML
              and CSS** on MySpace. That early curiosity grew into a deep
              interest in **web development, automation, and data analysis**.
              <br />
              <br />
              I graduated from **Lakeshore Technical College** in 2021 with an
              **Associate's Degree in IT-Web & Software Development** and a Web
              Development certificate. While in school, I worked as a **software
              development tutor**, helping students strengthen their coding
              skills. Today, I'm continuing my education at **Lakeland
              University**, pursuing a **Bachelor's in Data Science**, with
              plans to graduate in **December 2026**.
              <br />
              <br />
              Professionally, I work in **predictive maintenance and reliability
              engineering**, using **ultrasound, lubrication, and data-driven
              diagnostics** to ensure industrial equipment stays in peak
              condition. My technical expertise spans **React, Python, Firebase,
              SQL, and machine learning**, and I run **Three14 LLC**, where I
              build websites, apps, and automation solutions for businesses.
              <br />
              <br />
              My current focus is on merging **AI, automation, and predictive
              analytics** to create smarter software solutions. Whether it's
              optimizing industrial maintenance, developing business
              applications, or crafting intuitive front-end designs, I'm always
              looking to push the boundaries of technology.
            </p>
            <div className="img">
              <img src={Avatar} alt="Carlos Guzman/Me" />
              <div className="overlay"></div>
            </div>
          </div>
        </div>
      </div>

      <div className="full container fontRoboto" id="experience">
        <div className="workExperience">
          <h1>My Experience</h1>

          <div className="job">
            <h1>Three14 LLC</h1>
            <div className="content">
              <div className="title">
                <h1>Software Developer & Founder</h1>
                <h1 className="lightBlue"> @ Three14 LLC </h1>
              </div>
              <p>February 2022 - Present</p>
              <p>
                <FiChevronsRight className="lightBlue" /> Founded and currently
                manage a software development company.
              </p>
              <p>
                <FiChevronsRight className="lightBlue" /> Developed **custom
                websites and applications** tailored to client needs.
              </p>
              <p>
                <FiChevronsRight className="lightBlue" /> Implemented **React,
                Firebase, and modern UI frameworks** for scalable solutions.
              </p>
              <p>
                <FiChevronsRight className="lightBlue" /> Built and optimized
                **front-end and back-end systems** for various projects.
              </p>
            </div>
          </div>

          <div className="job">
            <h1>Johnsonville</h1>
            <div className="content">
              <div className="title">
                <h1>Predictive Maintenance Technician</h1>
                <h1 className="lightBlue"> @ Johnsonville Sausage </h1>
              </div>
              <p>2023 - Present</p>
              <p>
                <FiChevronsRight className="lightBlue" /> Conduct **ultrasound
                and vibration analysis** to detect early signs of equipment
                failure.
              </p>
              <p>
                <FiChevronsRight className="lightBlue" /> Use **Spectralyzer and
                DMS software** to analyze waveform data for predictive
                maintenance.
              </p>
              <p>
                <FiChevronsRight className="lightBlue" /> Support reliability
                initiatives by integrating **data-driven insights** to improve
                equipment lifespan.
              </p>
              <p>
                <FiChevronsRight className="lightBlue" /> Work closely with
                maintenance teams to implement **preventive maintenance
                strategies**.
              </p>
            </div>
          </div>

          <div className="job">
            <h1>Lakeshore Technical College</h1>
            <div className="content">
              <div className="title">
                <h1>Software Development Tutor</h1>
                <h1 className="lightBlue"> @ Lakeshore Technical College </h1>
              </div>
              <p>January 2021 - May 2021</p>
              <p>
                <FiChevronsRight className="lightBlue" /> Tutored **HTML, CSS,
                JavaScript, and software development fundamentals**.
              </p>
              <p>
                <FiChevronsRight className="lightBlue" /> Assisted first-year
                students in understanding **coding principles and best
                practices**.
              </p>
              <p>
                <FiChevronsRight className="lightBlue" /> Provided **one-on-one
                mentorship** to improve problem-solving and debugging skills.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div id="latest1"></div>
      <div className="full container fontRoboto" id="latest">
        <h1>Latest Projects</h1>

        <div className="project">
          <a
            href="http://cvrlxsguzmanhosting.com/outfurdelivery/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={OutFurDelivery} alt="Out Fur Delivery Website" />
          </a>
          <div className="details">
            <h1>Out Fur Delivery</h1>
            <p>
              A web application developed as a semester project, in
              collaboration with a marketing and two graphic design students. I
              was the sole developer, handling both the front-end and back-end.
            </p>
            <p>
              <FiChevronsRight className="lightBlue" /> **Technologies Used:**
              PHP, MySQL, Bootstrap, HTML, CSS
            </p>
            <a
              href="http://cvrlxsguzmanhosting.com/outfurdelivery/"
              target="_blank"
              className="link"
            >
              <FiExternalLink /> View Project
            </a>
          </div>
        </div>

        <div className="project">
          <a
            href="https://secndchance.us/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={SecndChance} alt="SecndChance Website" />
          </a>
          <div className="details">
            <h1>SecndChance</h1>
            <p>
              A minimalist and user-friendly website built for a client based on
              their wireframes. The project focused on creating an intuitive
              user experience with **React and Firebase**.
            </p>
            <p>
              <FiChevronsRight className="lightBlue" /> **Technologies Used:**
              ReactJS, SCSS, Firebase Database
            </p>
            <a href="https://secndchance.us/" target="_blank" className="link">
              <FiExternalLink /> View Project
            </a>
          </div>
        </div>

        <div className="project">
          <a
            href="https://three14.llc/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={Three14} alt="Three14 LLC Website" />
          </a>
          <div className="details">
            <h1>Three14</h1>
            <p>
              The main website for my company, **Three14 LLC**, showcasing the
              services offered, portfolio, and contact information. Still under
              development, with plans to integrate **data analytics and
              automation** in future iterations.
            </p>
            <p>
              <FiChevronsRight className="lightBlue" /> **Technologies Used:**
              ReactJS, SCSS, Firebase Hosting
            </p>
            <a href="https://three14.llc/" target="_blank" className="link">
              <FiExternalLink /> View Project
            </a>
            <p>
              <i>
                <small>*Work in Progress</small>
              </i>
            </p>
          </div>
        </div>

        <div className="project">
          <a
            href="https://imgraphy-9292e.firebaseapp.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={Alpha} alt="Alpha Social Media Mock Website" />
          </a>
          <div className="details">
            <h1>Alpha Social</h1>
            <p>
              A social media **mock website** designed with profile creation,
              user posts, and follow features. The project explores real-time
              interactions using **Firebase Authentication and Database**.
            </p>
            <p>
              <FiChevronsRight className="lightBlue" /> **Technologies Used:**
              ReactJS, SCSS, Firebase Authentication, Firebase Database
            </p>
            <a
              href="https://imgraphy-9292e.firebaseapp.com/"
              target="_blank"
              className="link"
            >
              <FiExternalLink /> View Project
            </a>
            <p>
              <i>
                <small>*Work in Progress</small>
              </i>
            </p>
          </div>
        </div>

        <div className="project">
          <a
            href="https://ecommerce-store-template.firebaseapp.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={Ecommerce} alt="Ecommerce Website" />
          </a>
          <div className="details">
            <h1>Ecommerce Store Template</h1>
            <p>
              A **React-based eCommerce template** with authentication and a
              basic product display. This serves as a foundation for future
              scalable online stores.
            </p>
            <p>
              <FiChevronsRight className="lightBlue" /> **Technologies Used:**
              ReactJS, SCSS, Redux, Firebase
            </p>
            <a
              href="https://ecommerce-store-template.firebaseapp.com/"
              target="_blank"
              className="link"
            >
              <FiExternalLink /> View Project
            </a>
            <p>
              <i>
                <small>*Work in Progress</small>
              </i>
            </p>
          </div>
        </div>

        <div className="project">
          <a
            href="https://old-portfolio-2d938.firebaseapp.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={OldPortfolio} alt="Carlos Guzman's Old Portfolio" />
          </a>
          <div className="details">
            <h1>Previous Portfolio</h1>
            <p>
              My previous portfolio site, featuring a **multi-page layout**
              instead of a single-page design. It showcases my earlier web
              development work.
            </p>
            <p>
              <FiChevronsRight className="lightBlue" /> **Technologies Used:**
              ReactJS, SCSS
            </p>
            <a
              href="https://old-portfolio-2d938.firebaseapp.com/"
              target="_blank"
              className="link"
            >
              <FiExternalLink /> View Project
            </a>
          </div>
        </div>
      </div>

      <div className="full container fontRoboto" id="contact">
        <h1>Get In Touch</h1>
        <p>
          Feel free to contact me for any reason. My inbox is always open. I
          will try my best to reply promptly. Stay golden!
        </p>
        <div className="contactInfo">
          <IoMailOutline className="icon" />
          <div className="details">
            <h2>Email</h2>
            <p>cvrlxsguzman@gmail.com</p>
          </div>
        </div>
        <div className="contactInfo">
          <IoLocationOutline className="icon" />
          <div className="details">
            <h2>Location</h2>
            <p>Sheboygan, Wisconsin, United States</p>
          </div>
        </div>
        <a href="mailto:cvrlxsguzman@gmail.com" className="button lightGray">
          Contact Me
        </a>
      </div>

      {/* Footer */}
      <div className="footer fontCode" id="footer">
        <p className="fontCode">
          <small>By Carlos Guzman 2025</small>
        </p>
      </div>
    </div>
  );
};

export default HomePage;
