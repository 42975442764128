import React, { useEffect, useState } from "react";
import {
  Box,
  Card,
  CardContent,
  Typography,
  Container,
  Button,
  Grid,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from "@mui/material";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { auth, db } from "../firebase/config";
import { signOut } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import {
  collection,
  getDocs,
  addDoc,
  updateDoc,
  deleteDoc,
  doc,
  query,
  where,
  Timestamp,
} from "firebase/firestore";
import CurrentEvents from "../components/CurrentEvents";

const localizer = momentLocalizer(moment);

function WorkCalendar() {
  const [events, setEvents] = useState([]);
  const [greeting, setGreeting] = useState("");
  const [openDialog, setOpenDialog] = useState(false);
  const [eventDetails, setEventDetails] = useState(null);
  const [newEventTitle, setNewEventTitle] = useState("");
  const [newEventDate, setNewEventDate] = useState("");
  const [recurringOption, setRecurringOption] = useState("none");
  
  const user = auth.currentUser;
  const navigate = useNavigate();
  
  useEffect(() => {
    const username = user?.displayName || user?.email;
    
    const messages = [
      "Time to get some work done... or pretend to!",
      "Did you know? The average person will spend 90,000 hours at work in their lifetime.",
      "Why did the scarecrow get promoted? Because he was outstanding in his field!",
      "Don't worry, calendars are just a suggestion, right?",
      "Another day, another dollar... or maybe a few cents after taxes.",
      "Remember, coffee first, work later!",
      "Why did the calendar break up with the clock? It found the relationship too time-consuming!",
    ];
    
    const randomMessage = messages[Math.floor(Math.random() * messages.length)];
    setGreeting(`Hello ${username}! ${randomMessage}`);
  }, [user]);
  
  useEffect(() => {
    const fetchEvents = async () => {
      try {
        const usersCollection = collection(db, "users");
        const eventsCollection = collection(db, "events");
        const usersSnapshot = await getDocs(usersCollection);
        const eventsSnapshot = await getDocs(eventsCollection);
        const currentYear = new Date().getFullYear();
        
        const fetchedEvents = [];
        
        // Fetch user birthdays
        usersSnapshot.docs.forEach((doc) => {
          const data = doc.data();
          if (data.birthday && data.displayName) {
            const birthdayDate = moment(data.birthday, "YYYY-MM-DD");
            
            const recurringBirthday = birthdayDate.year(currentYear);
            fetchedEvents.push({
              title: `${data.displayName || data.email}'s Birthday!`,
              start: recurringBirthday.toDate(),
              end: recurringBirthday.toDate(),
              allDay: true,
              recurring: "yearly",
              createdBy: data.displayName || data.email,
            });
          }
        });
        
        // Fetch other events
        eventsSnapshot.docs.forEach((doc) => {
          const data = doc.data();
          let eventDate = moment(data.date, "YYYY-MM-DD");
          
          // Generate events based on recurrence
          if (data.recurring === "yearly") {
            for (let year = currentYear - 1; year <= currentYear + 1; year++) {
              const recurringDate = eventDate.clone().year(year);
              fetchedEvents.push({
                ...data,
                id: doc.id,
                start: recurringDate.toDate(),
                end: recurringDate.toDate(),
                allDay: true,
              });
            }
          } else if (data.recurring === "monthly") {
            for (let month = 0; month < 12; month++) {
              const recurringDate = eventDate.clone().month(month);
              fetchedEvents.push({
                ...data,
                id: doc.id,
                start: recurringDate.toDate(),
                end: recurringDate.toDate(),
                allDay: true,
              });
            }
          } else if (data.recurring === "weekly") {
            for (let week = -1; week <= 1; week++) {
              const recurringDate = eventDate.clone().add(week * 7, "days");
              fetchedEvents.push({
                ...data,
                id: doc.id,
                start: recurringDate.toDate(),
                end: recurringDate.toDate(),
                allDay: true,
              });
            }
          } else if (data.recurring === "daily") {
            for (let day = -7; day <= 7; day++) {
              const recurringDate = eventDate.clone().add(day, "days");
              fetchedEvents.push({
                ...data,
                id: doc.id,
                start: recurringDate.toDate(),
                end: recurringDate.toDate(),
                allDay: true,
              });
            }
          } else {
            // Non-recurring events
            fetchedEvents.push({
              ...data,
              id: doc.id,
              start: eventDate.toDate(),
              end: eventDate.toDate(),
              allDay: true,
            });
          }
        });
        
        setEvents(fetchedEvents);
      } catch (error) {
        console.error("Error fetching events:", error);
      }
    };
    
    fetchEvents();
  }, []);
  
  const handleSignOut = () => {
    signOut(auth)
      .then(() => {
        navigate("/login");
      })
      .catch((error) => {
        console.error("Error signing out:", error);
      });
  };
  
  const handleGoToProfile = () => {
    navigate("/profile");
  };
  
  const handleOpenDialog = () => {
    setOpenDialog(true);
  };
  
  const handleCloseDialog = () => {
    setOpenDialog(false);
    setNewEventTitle("");
    setNewEventDate("");
    setRecurringOption("none");
  };
  
  const handleCreateEvent = async () => {
    if (newEventTitle && newEventDate) {
      try {
        const eventDate = moment(newEventDate, "YYYY-MM-DD");
        
        const eventData = {
          title: newEventTitle,
          date: eventDate.format("YYYY-MM-DD"),
          createdBy: user?.displayName || user?.email,
          createdAt: Timestamp.now(),
          recurring: recurringOption,
        };
        
        const eventRef = await addDoc(collection(db, "events"), eventData);
        
        setEvents((prevEvents) => [
          ...prevEvents,
          {
            id: eventRef.id,
            ...eventData,
            start: eventDate.toDate(),
            end: eventDate.toDate(),
            allDay: true,
          },
        ]);
        
        handleCloseDialog();
      } catch (error) {
        console.error("Error creating event:", error);
      }
    }
  };
  
  const handleSelectEvent = async (event) => {
    setEventDetails(event);
  };
  
  const handleCloseEventDetails = () => {
    setEventDetails(null);
  };
  
  const handleEditEvent = async () => {
    if (eventDetails && newEventTitle && newEventDate) {
      try {
        const eventDoc = doc(db, "events", eventDetails.id);
        const updatedDate = moment(newEventDate, "YYYY-MM-DD");
        
        await updateDoc(eventDoc, {
          title: newEventTitle,
          date: updatedDate.format("YYYY-MM-DD"),
          recurring: recurringOption,
        });
        
        setEvents((prevEvents) =>
          prevEvents.map((e) =>
            e.id === eventDetails.id
              ? {
                ...e,
                title: newEventTitle,
                start: updatedDate.toDate(),
                end: updatedDate.toDate(),
                recurring: recurringOption,
              }
              : e
          )
        );
        
        handleCloseEventDetails();
      } catch (error) {
        console.error("Error updating event:", error);
      }
    }
  };
  
  const handleDeleteEvent = async () => {
    if (eventDetails) {
      try {
        const eventDoc = doc(db, "events", eventDetails.id);
        await deleteDoc(eventDoc);
        
        setEvents((prevEvents) => prevEvents.filter((e) => e.id !== eventDetails.id));
        handleCloseEventDetails();
      } catch (error) {
        console.error("Error deleting event:", error);
      }
    }
  };
  
  return (
    <Box
      sx={{
        minHeight: "100vh",
        backgroundColor: "primary.main",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        paddingTop: 4,
      }}
    >
      <Container maxWidth="lg">
        <Card sx={{ borderRadius: 2, boxShadow: 5 }}>
          <CardContent>
            <Grid container spacing={2} justifyContent="space-between" alignItems="center">
              <Grid item xs={12}>
                <Typography variant="h5" gutterBottom align="left">
                  {greeting}
                </Typography>
              </Grid>
              <Grid item>
                <Button variant="contained" color="secondary" onClick={handleSignOut}>
                  Sign Out
                </Button>
              </Grid>
              <Grid item>
                <Button variant="contained" color="primary" onClick={handleGoToProfile}>
                  Profile
                </Button>
              </Grid>
              <Grid item>
                <Button variant="contained" color="primary" onClick={handleOpenDialog}>
                  Create Event
                </Button>
              </Grid>
            </Grid>
            <Calendar
              localizer={localizer}
              events={events}
              startAccessor="start"
              endAccessor="end"
              style={{ height: 500, margin: "30px" }}
              onSelectEvent={handleSelectEvent}
            />
          </CardContent>
        </Card>
        
        {/* Add the CurrentEvents Component */}
        <CurrentEvents events={events} />
      </Container>
      
      {/* Dialog for Creating a New Event */}
      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>Create New Event</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Event Title"
            type="text"
            fullWidth
            value={newEventTitle}
            onChange={(e) => setNewEventTitle(e.target.value)}
          />
          <TextField
            margin="dense"
            label="Event Date"
            type="date"
            fullWidth
            value={newEventDate}
            onChange={(e) => setNewEventDate(e.target.value)}
            InputLabelProps={{
              shrink: true,
            }}
          />
          <FormControl fullWidth sx={{ mt: 2 }}>
            <InputLabel>Recurring</InputLabel>
            <Select
              value={recurringOption}
              label="Recurring"
              onChange={(e) => setRecurringOption(e.target.value)}
            >
              <MenuItem value="none">None</MenuItem>
              <MenuItem value="daily">Daily</MenuItem>
              <MenuItem value="weekly">Weekly</MenuItem>
              <MenuItem value="monthly">Monthly</MenuItem>
              <MenuItem value="yearly">Yearly</MenuItem>
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="secondary">
            Cancel
          </Button>
          <Button onClick={handleCreateEvent} color="primary">
            Create
          </Button>
        </DialogActions>
      </Dialog>
      
      {/* Dialog for Viewing and Managing Event Details */}
      <Dialog open={!!eventDetails} onClose={handleCloseEventDetails}>
        <DialogTitle>Event Details</DialogTitle>
        <DialogContent>
          {eventDetails && (
            <>
              <Typography variant="h6">Title: {eventDetails.title}</Typography>
              <Typography variant="body1">
                Date: {moment(eventDetails.start).format("MMMM Do, YYYY")}
              </Typography>
              <Typography variant="body1">Created By: {eventDetails.createdBy}</Typography>
              <Typography variant="body1">
                Created At: {moment(eventDetails.createdAt).format("MMMM Do, YYYY, h:mm a")}
              </Typography>
              
              {eventDetails.createdBy === (user?.displayName || user?.email) && (
                <>
                  <TextField
                    margin="dense"
                    label="Edit Event Title"
                    type="text"
                    fullWidth
                    value={newEventTitle}
                    onChange={(e) => setNewEventTitle(e.target.value)}
                  />
                  <TextField
                    margin="dense"
                    label="Edit Event Date"
                    type="date"
                    fullWidth
                    value={newEventDate}
                    onChange={(e) => setNewEventDate(e.target.value)}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                  <FormControl fullWidth sx={{ mt: 2 }}>
                    <InputLabel>Edit Recurring</InputLabel>
                    <Select
                      value={recurringOption}
                      label="Edit Recurring"
                      onChange={(e) => setRecurringOption(e.target.value)}
                    >
                      <MenuItem value="none">None</MenuItem>
                      <MenuItem value="daily">Daily</MenuItem>
                      <MenuItem value="weekly">Weekly</MenuItem>
                      <MenuItem value="monthly">Monthly</MenuItem>
                      <MenuItem value="yearly">Yearly</MenuItem>
                    </Select>
                  </FormControl>
                  <Button onClick={handleEditEvent} color="primary" sx={{ mt: 2 }}>
                    Save Changes
                  </Button>
                  <Button onClick={handleDeleteEvent} color="secondary" sx={{ mt: 1 }}>
                    Delete Event
                  </Button>
                </>
              )}
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseEventDetails} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}

export default WorkCalendar;
