import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "./firebase/config";
// Styles
import "./default.scss";
// Pages
import HomePage from "./pages/homepage";
import WorkCalendar from "./pages/workCalendar";
import LoginPage from "./pages/LoginPage";
import SignUpPage from "./pages/SignUpPage";
import ProfilePage from "./pages/ProfilePage";
// Theme
import { ThemeProvider } from "@mui/material/styles";
import theme from "./theme";

function App() {
  const [user, loading, error] = useAuthState(auth);
  
  if (loading) {
    return <div>Loading...</div>;
  }
  
  if (error) {
    console.error("Authentication Error:", error);
    return <div>Error: Unable to load user data</div>;
  }
  
  return (
    <ThemeProvider theme={theme}>
      <div className="App">
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/login" element={<LoginPage />} />
          <Route path="/signup" element={<SignUpPage />} />
          <Route
            path="/work-calendar"
            element={user ? <WorkCalendar /> : <Navigate to="/login" />}
          />
          <Route
            path="/profile"
            element={user ? <ProfilePage /> : <Navigate to="/login" />}
          />
        </Routes>
      </div>
    </ThemeProvider>
  );
}

export default App;
