import React, { useState, useEffect } from "react";
import { auth, db } from "../firebase/config";
import { updateProfile, updateEmail } from "firebase/auth";
import { doc, getDoc, setDoc, collection, getDocs, query, where, addDoc, deleteDoc, Timestamp } from "firebase/firestore";
import {
  Box,
  Container,
  Typography,
  TextField,
  Button,
  Grid,
  Card,
  CardContent,
  Alert,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import moment from "moment";

function ProfilePage() {
  const user = auth.currentUser;
  const [name, setName] = useState(user?.displayName || "");
  const [email, setEmail] = useState(user?.email || "");
  const [birthday, setBirthday] = useState("");
  const [message, setMessage] = useState("");
  const [userEvents, setUserEvents] = useState([]);
  const navigate = useNavigate();
  
  useEffect(() => {
    if (user) {
      const fetchProfileData = async () => {
        try {
          const userDoc = await getDoc(doc(db, "users", user.uid));
          if (userDoc.exists()) {
            setBirthday(userDoc.data().birthday || "");
          }
          
          // Fetch user's events
          const eventsCollection = collection(db, "events");
          const userEventsQuery = query(eventsCollection, where("createdBy", "==", user?.displayName || user?.email));
          const eventsSnapshot = await getDocs(userEventsQuery);
          const eventsData = eventsSnapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
          setUserEvents(eventsData);
        } catch (error) {
          console.error("Error fetching profile data or events:", error);
        }
      };
      fetchProfileData();
    }
  }, [user]);
  
  const handleUpdateProfile = async () => {
    try {
      if (user) {
        // Update user's name if it has changed
        if (user.displayName !== name) {
          await updateProfile(user, { displayName: name });
        }
        
        // Update user's email if it has changed
        if (user.email !== email) {
          await updateEmail(user, email);
        }
        
        // Save user's birthday in Firestore
        await setDoc(doc(db, "users", user.uid), { birthday }, { merge: true });
        
        // Check if the birthday is newly added or updated
        if (birthday) {
          const eventsCollection = collection(db, "events");
          const birthdayEventQuery = query(
            eventsCollection,
            where("createdBy", "==", user?.displayName || user?.email),
            where("recurring", "==", "yearly"),
            where("title", "==", `${name || email}'s Birthday!`)
          );
          const birthdayEventSnapshot = await getDocs(birthdayEventQuery);
          
          // Delete existing birthday events if present
          birthdayEventSnapshot.forEach(async (doc) => {
            await deleteDoc(doc.ref);
          });
          
          // Create new birthday event
          const birthdayDate = moment(birthday, "YYYY-MM-DD").year(new Date().getFullYear());
          const eventData = {
            title: `${name || email}'s Birthday!`,
            date: birthdayDate.format("YYYY-MM-DD"),
            createdBy: user?.displayName || user?.email,
            createdAt: Timestamp.now(),
            recurring: "yearly", // Recurring yearly
          };
          
          await addDoc(collection(db, "events"), eventData);
        }
        
        setMessage("Profile updated successfully!");
      }
    } catch (error) {
      console.error("Error updating profile:", error);
      setMessage("Failed to update profile. Please check your information.");
    }
  };
  
  const handleGoToCalendar = () => {
    navigate("/work-calendar");
  };
  
  return (
    <Box
      sx={{
        minHeight: "100vh",
        backgroundColor: "primary.main",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Card sx={{ width: 400, padding: 3, borderRadius: 2, boxShadow: 5 }}>
        <CardContent>
          <Typography variant="h4" align="center" gutterBottom>
            Profile
          </Typography>
          {message && (
            <Alert severity={message.includes("successfully") ? "success" : "error"} sx={{ mb: 2 }}>
              {message}
            </Alert>
          )}
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Name"
                variant="outlined"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Email"
                variant="outlined"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Birthday"
                variant="outlined"
                type="date"
                value={birthday}
                onChange={(e) => setBirthday(e.target.value)}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <Button fullWidth variant="contained" color="primary" onClick={handleUpdateProfile}>
                Update Profile
              </Button>
            </Grid>
            <Grid item xs={12}>
              <Button fullWidth variant="outlined" color="secondary" onClick={handleGoToCalendar}>
                Back to Calendar
              </Button>
            </Grid>
          </Grid>
          
          <Typography variant="h5" sx={{ mt: 4 }}>
            Your Events
          </Typography>
          <List>
            {userEvents.map((event) => (
              <ListItem key={event.id}>
                <ListItemText
                  primary={event.title}
                  secondary={`Date: ${moment(event.date, "YYYY-MM-DD").format(
                    "MMMM Do, YYYY"
                  )} | Created on: ${moment(event.createdAt?.toDate()).format("MMMM Do, YYYY, h:mm a")}`}
                />
              </ListItem>
            ))}
          </List>
        </CardContent>
      </Card>
    </Box>
  );
}

export default ProfilePage;
