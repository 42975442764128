import { createTheme } from "@mui/material/styles";
import { indigo, pink } from "@mui/material/colors";

const theme = createTheme({
  palette: {
    primary: {
      main: indigo[500], // Use indigo as the primary color
    },
    secondary: {
      main: pink[500], // Choose a secondary color that contrasts well, like pink
    },
  },
  typography: {
    fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`,
  },
});

export default theme;
