import React from "react";
import { Card, CardContent, Typography, List, ListItem, ListItemText } from "@mui/material";
import moment from "moment";

function CurrentEvents({ events }) {
  const currentMonth = moment().month();
  const eventsThisMonth = events.filter((event) => moment(event.start).month() === currentMonth);
  
  return (
    <Card sx={{ borderRadius: 2, boxShadow: 5, marginTop: 4 }}>
      <CardContent>
        <Typography variant="h6" gutterBottom>
          Events Happening This Month
        </Typography>
        <List>
          {eventsThisMonth.length > 0 ? (
            eventsThisMonth.map((event) => (
              <ListItem key={event.id}>
                <ListItemText
                  primary={event.title}
                  secondary={`Date: ${moment(event.start).format("MMMM Do, YYYY")}`}
                />
              </ListItem>
            ))
          ) : (
            <Typography variant="body1">No events this month.</Typography>
          )}
        </List>
      </CardContent>
    </Card>
  );
}

export default CurrentEvents;
